@keyframes gradient-slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animateGradientSlide {
  animation: gradient-slide 2s linear infinite;
}
