@keyframes fadeInOut {
  10% {
    right: 30px;
  }
  80% {
    opacity: 1;
    right: 30px;
  }
  100% {
    opacity: 0;
    right: 30px;
  }
}

.container {
  position: absolute !important;
  bottom: 30px;
  z-index: 10000000000000;
  right: -300px;
  max-width: 300px;
  padding: 10px 15px 10px 10px;
  outline: none;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  background-size: cover !important;
  animation: fadeInOut 4s;
  animation-timing-function: ease-in;
}

#lightContainer {
  background: linear-gradient(
      103.07deg,
      rgba(255, 255, 255, 0.24) 28.43%,
      rgba(255, 255, 255, 0.06) 75.73%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
    url("../assets/frosted_glass40.png");
}

#darkContainer {
  background: linear-gradient(
      103.07deg,
      rgba(255, 255, 255, 0.16) 28.43%,
      rgba(255, 255, 255, 0.04) 75.73%
    ),
    linear-gradient(0deg, rgba(36, 33, 65, 0.71), rgba(36, 33, 65, 0.71)),
    url("../assets/frosted_glass40.png");
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.title {
  display: flex;
  justify-content: center;
}

@media (max-width: 1100px) {
  .container {
    /* width: 200px; */
    /* height: 200px;
    overflow-y: auto; */
  }
}
