.icon {
  height: 26px;
}

.navButton {
}

.selectedLink {
  background: linear-gradient(180deg, #8b50eb 0%, #6fa8ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.drawerBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

.drawerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 240px;
  max-width: 75vw;
  max-width: 44rem;
  background: black;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
}

.drawerBackdropOpen {
  opacity: 1;
  visibility: visible;
}
