.component {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

input[type="checkbox"] {
  appearance: none;
  min-width: 16px;
  height: 16px;
  border: 1px solid #888;
  border-radius: 50% !important;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  position: relative;
}

input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 3px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
  left: 3px;
  top: 4px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

input[type="checkbox"]:checked {
  background-color: rgb(200, 200, 200);
}

input[type="checkbox"]:checked:before {
  opacity: 1;
}

.disabled {
  opacity: 0.5;
  cursor: auto !important;
}
