.component {
  width: 100%;
}

.component > div:first-child {
  border-radius: 10px 0px 0px 10px;
}

.component > div:last-child {
  border-radius: 0px 10px 10px 0px;
}

.option,
.selected {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.option:hover,
.selected:hover {
  cursor: pointer;
}

.option {
  background-color: #27272a;
}

.selected {
  background-color: #52525b;
}
