.modalWrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100vh;
}
.hideScroll::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
