.form {
  width: 560px !important;
  height: 500px;
}

.notificationsNumber {
  height: 18px;
  width: 18px;
  background-color: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 15px;
  bottom: 44px;
}

.box {
  position: absolute;
  height: fit-content;
  max-height: 265px;
  width: 250px;
  transform: translateX(-220px);
  margin: 10px;
  margin-top: 2px;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  justify-content: center !important;
  background: #1c1c1e;
  z-index: 10;
  top: 80px;
}

.mobile {
  top: 70px;
  transform: translateX(-230px);
}

.box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.2) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.notifications {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  gap: 10px;
  padding-top: 5px;
  position: relative;
  overflow-x: hidden;
}

.notificationButton {
  padding: 0px !important;
  margin: 0px !important;
}

.triangle {
  position: absolute;
  top: 34px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #59595b;
}
