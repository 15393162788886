.box {
  position: absolute;
  z-index: 10;
  top: 80px;
  transform: translateX(-180px);
}

.mobile {
  top: 70px;
  transform: translateX(-150px);
}

.menu {
  width: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.menuGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 5px;
}

.usd {
  opacity: 0.8;
  font-size: 12px !important;
  margin-top: 2px !important;
}

.button {
  font-style: italic;
  font-weight: 600 !important;
  font-size: 18px !important;
  padding: 0 !important;
  margin-bottom: 1px !important;
  width: 100%;
  justify-content: center;
  margin: 5px 0;
}

.iconSmall {
  height: 15px;
  margin-left: 10px;
}

.iconMedium {
  height: 22px;
  margin-left: 5px;
}

.divider {
  height: 2px !important;
  width: 85%;
  margin: 0 auto !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.profileButton {
  padding: 0 !important;
}

.triangle {
  position: absolute;
  top: 103%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #59595b;
}
