.title1,
.title2,
.body1,
.body2,
.subtitle,
.footer1,
.footer2 {
  flex-wrap: wrap;
  margin: 0 !important;
  display: flex;
  font-family: "Be Vietnam Pro", sans-serif !important;
  font-style: normal !important;
}

.title1 {
  font-weight: 800 !important;
  margin: 0 auto 8px !important;
  width: fit-content;
  position: relative;
  padding: 8px 15px;
  background: linear-gradient(
    103.07deg,
    rgba(216, 216, 216, 0.24) 28.43%,
    rgba(168, 168, 168, 0.06) 75.73%
  );
  border-radius: 8px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
}

.title1::before {
  z-index: -1 !important;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  border: 1px solid transparent;
  background: linear-gradient(
      105.98deg,
      rgba(255, 255, 255, 0.44) 25.28%,
      rgba(214, 178, 243, 0.49) 43.24%,
      rgba(181, 116, 233, 0.48) 57.57%,
      rgba(155, 31, 252, 0.69) 71.89%
    )
    border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.title2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 29px;
  text-align: center;
  text-shadow: 0px 2px 4px #00000026;
  color: var(--white75);
  font-size: var(--font-size-xxl);
  font-weight: 700 !important;
  display: flex;
  justify-content: center;
}

.MuiTypography-h6 {
  font-family: none !important;
}

.body1 {
  font-size: 30px !important;
  font-weight: 900 !important;
  line-height: 36px !important;
}

/* .body2 {
  font-size: 24px !important;
  font-weight: 800 !important;
  line-height: 32px !important;
} */
.body2 {
  font-size: 16px !important;
  font-weight: 900 !important;
  line-height: 21px !important;
  letter-spacing: 0;
}

.subtitle {
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 24px !important;
}

.body1,
.body2,
.subtitle {
  font-weight: 800 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer1 {
  font-size: 14px !important;
  font-weight: 900 !important;
  line-height: 21px !important;
  height: 21px;
  letter-spacing: 0;
  margin-top: 0.5px;
  white-space: nowrap;
  color: rgba(229, 231, 235, 1) !important;
  font-style: normal;
}

.footer2 {
  font-weight: 900 !important;
  line-height: 20px !important;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: -1px;
  white-space: nowrap;
  color: rgba(229, 231, 235, 0.3) !important;
  font-size: 14px !important;
  font-style: normal;
}

.link {
  text-decoration: none !important;
}

#lightThemeDarkGrayText,
#darkThemeDarkGrayText {
  color: var(--dark-gray) !important;
}

#lightThemeLightGrayText,
#darkThemeLightGrayText {
  color: var(--light-gray) !important;
}

#lightThemeMediumGrayText,
#darkThemeMediumGrayText {
  color: var(--medium-gray) !important;
}

#lightThemeOffWhiteText,
#darkThemeOffWhiteText {
  color: var(--off-white) !important;
}

#lightThemeFadedWhiteText,
#darkThemeFadedWhiteText {
  color: var(--faded-white) !important;
}

#lightThemeBlackText {
  color: rgba(0, 0, 0, 0.85) !important;
}

#darkThemeBlackText {
  color: rgba(0, 0, 0, 0.85) !important;
}

#lightThemeWhiteText {
  color: rgba(255, 255, 255, 0.95);
}

#darkThemeWhiteText {
  color: rgba(255, 255, 255, 0.9);
}

/* Constant Colors */
#lightThemeWhiteConstant {
  color: rgba(255, 255, 255, 0.95) !important;
}

#darkThemeWhiteConstant {
  color: rgba(255, 255, 255, 0.9);
}

#lightThemeGreyText,
#darkThemeGreyText {
  color: rgba(255, 255, 255, 0.8) !important;
}

#lightThemeRedText,
#darkThemeRedText {
  color: #ff0000 !important;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

#lightThemeGreenText,
#darkThemeGreenText {
  color: #00ff00 !important;
  animation: blink 2s ease infinite;
}

@media (max-width: 1100px) {
  .body1 {
    font-size: 24px !important;
    line-height: 32px !important;
  }
  .body2 {
    font-size: 14px !important;
  }
  .subtitle {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .footer1 {
    font-size: 12px !important;
    line-height: 16px !important;
  }
  .footer2 {
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
  }
}
