.icon {
  height: 25px;
  width: 25px;
  margin: 10px;
  margin-left: 5px;
}

a:active,
a:focus {
  text-decoration: none;
}

@media (max-width: 1100px) {
  .icon {
    height: 20px;
    width: 20px;
  }
}
