.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 15px 0;
  width: 100%;
}

#lightIcon,
#darkIcon {
  margin-bottom: 5px;
}

#lightIcon {
  color: rgba(0, 0, 0, 0.85) !important;
}

#darkIcon {
  color: rgba(255, 255, 255, 0.9) !important;
}
