@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;500;600;700;800;900&family=DM+Sans:wght@400;700&display=swap");

html,
body {
  height: 100%;
  margin: 0;
  background-color: black;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  caret-color: transparent;

  /* Vars */
  --bottom-gap: 32px;

  /* Vars from Anima conversion */
  --black: rgba(0, 0, 0, 0.8);
  --black-85: #000000d9;
  --black-100: #000000;
  --white-100: #ffffff;
  --white75: #ffffff;
  --mercury: #e5e7eb;
  --off-white: #e5e7eb;
  --apple-light-gray: #7676803d;

  /* New Figma colors */
  --dark-gray: #18181b;
  --light-gray: #52525b;
  --medium-gray: #27272a;
  --faded-white: #d4d4d8;

  --font-size-l: 36px;
  --font-size-m: 64px;
  --font-size-s: 20px;
  --font-size-xs: 16px;

  --main-font: "Be Vietnam Pro", sans-serif !important;

  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiButton-root {
  text-transform: none !important;
}

.blurBackground {
  background-color: rgba(43, 43, 43, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.blurBackgroundXL {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.custom-scrollbar {
  box-sizing: content-box;
  padding-right: 4px;
  margin-right: -12px;

  scrollbar-width: 4px;
  scrollbar-color: gray transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  scrollbar-width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--medium-gray);
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.scrollbar-thin::-webkit-scrollbar {
  height: 2px;
  width: 2px;
  background-color: transparent;
}
.scrollbar-thin::-webkit-scrollbar-corner {
  background: transparent;
}


/* Input styles */
input {
  caret-color: white;
}
/* Hide up/down arrows for Webkit browsers like Chrome and Safari */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Hide up/down arrows for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
