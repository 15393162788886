.component {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.terms {
  align-items: center;
  display: flex;
  gap: 4px;
  position: relative;
  width: fit-content;
}

.buttons {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}

input[type="checkbox"] {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #888;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
}

input[type="checkbox"]:checked {
  background-color: rgb(200, 200, 200);
}
