.component {
  scrollbar-width: 0px;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.component::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.page {
  /* margin-top: 105px !important; */
  padding: 0 5%;
}

.page::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}
